import * as React from "react";
import { graphql } from "gatsby";

import "./thanks.scss";
import Shell from "../components/shell";
import { OutboundLink } from "gatsby-plugin-google-analytics";


export default class extends React.Component<{}, {}> {
  render() {


    return (
      <Shell>
        <div className="thanks fixed-width p-top-xlarge terms p-bottom-xlarge" >
          <img src={require('../assets/logo_black.svg')}/>
          <h1 className="fg-brand-tertiary">Thank you for applying.</h1>
          <p>
            Thanks for applying to the Go-No-Go Market validation programme. We’re looking 
            forward to learning more about your company and your ambitions to grow.
      <br /><br />
            We’ll be in touch with shortlisted companies to discuss plans in more 
            detail so we can arrive at our final decision. The Winner and 10 
            Runners up will be notified by email within 28 days of the closing date.
      <br /><br />
            You can learn more about Newfound on our website <OutboundLink target="_blank" rel="noopener" href="https://www.newfound.global/">www.newfound.global</OutboundLink>&nbsp;
            and connect with us on social media <OutboundLink target="_blank" rel="noopener" href="https://twitter.com/newfoundglobal">@newfoundglobal</OutboundLink>. You can also contact us at <a href="mailto:uk@newfound.global">uk@newfound.global</a>.
      <br /><br />
            Thanks from the team at Newfound.
      </p>
        </div>
      </Shell>
    )
  }
}
