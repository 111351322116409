import * as React from "react";
import Helmet from "react-helmet";
import PageTransition from 'gatsby-plugin-page-transitions';
import { Grid, Row, Col, Icon } from "armstrong-react";
import { Link } from "gatsby";


import { OutboundLink } from "gatsby-plugin-google-analytics";

import "../theme/theme.scss";
import "./shell.scss";

interface IProps {
  children: any;
}

interface IState {
  isIe: boolean;
}

class Shell extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { isIe: false }
  }
  componentDidMount() {
    if (navigator.appName == 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
      this.setState({ isIe: true })
    }
  }
  render() {
    if (this.state.isIe) {
      return (
        <div className="unsupported-browser">
          <h1>Unsupported browser</h1>
          <p>This site is currently unsupported on Microsoft Internet Explorer.
            You can either use <OutboundLink href="https://www.microsoft.com/en-gb/windows/microsoft-edge">Microsoft Edge</OutboundLink>,&nbsp;
            <OutboundLink href="https://www.google.com/chrome/">Google Chrome</OutboundLink> or&nbsp;
            <OutboundLink href="https://www.mozilla.org/en-GB/firefox/new/">Firefox</OutboundLink>.</p>
          <p>Alternatively catch up with us on social media via the links below:</p>
          <div className="socials">
            <OutboundLink target="_blank" href="https://www.facebook.com/newfoundglobal">Facebook</OutboundLink>
            <OutboundLink target="_blank" href="https://twitter.com/newfoundglobal">Twitter</OutboundLink>
            <OutboundLink target="_blank" href="https://www.linkedin.com/company/newfound-global/about">Linkedin</OutboundLink>
          </div>
        </div>
      )
    }
    return (
      <>
        <main>
          <PageTransition>
            {this.props.children}
          </PageTransition>
        </main>
      </>
    );
  }
}

export default Shell;
